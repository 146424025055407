import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'
import { Colors } from '@/constants/styles/color'
import Official from '@/assets/img/icons/Official.svg'

const CommonStyle = css`
  width: 100%;
  margin: 0 auto;
`

export const StyledImgWrapper = styled.div<{ width?: number; height?: number }>`
  width: ${({ width }) => width || 64}px;
  height: ${({ height }) => height || 64}px;
  margin: 0 auto;
`

export const StyledCreatorCoverImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: scale-down;
`

export const StyledHeaderImg = styled.img`
  width: 100%;
`

export const StyledContainer = styled.div<{ displayStyle?: string }>`
  display: ${({ displayStyle }) => displayStyle || 'flex'};
  max-width: 480px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px;
  position: fixed;
  z-index: 99;
  right: 0px;
  left: 0px;
  height: 56px;
  ${CommonStyle}
`

export const StyledViewCover = styled.div<{ bgImage?: string; bgColor?: string }>`
  position: relative;
  z-index: 98;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  scroll-snap-align: start;
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'none')};
  background-color: ${({ bgColor }) => (bgColor ? '' : Colors.BK_GRAY)};
`

export const StyledInfoIconWrapper = styled.div`
  ${CommonStyle}
  margin-bottom: 8px;
`

export const StyledCreatorInfoNameWrapper = styled.div`
  ${CommonStyle}
  font-weight: bold;
  margin-bottom: 8px;
  color: ${Colors.WHITE};
`

export const StyledScrollWrapper = styled.div`
  ${CommonStyle}
`

export const StyledSnsLinkWrapperTop = styled.div`
  margin: 16px;
`

export const StyledSnsLinkWrapperBottom = styled.div`
  margin: 0 16px;
`

export const StyledScrollText = styled.p`
  font-family: 'Noto Sans JP';
  line-height: 10px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 24px;
  margin: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.WHITE};
`

export const StyledCreatorInfoName = styled.p`
  line-height: 13px;
  text-align: center;
  letter-spacing: 0em;
  vertical-align: middle;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin: 0;
  text-transform: none;
  color: ${Colors.WHITE};
`

export const StyledGradationTop = styled.div<{ gradation?: string }>`
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(-180deg);
  background: ${({ gradation }) => gradation};
`

export const StyledGradationBottom = styled.div<{ gradation?: string }>`
  width: 100%;
  height: 184px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: ${({ gradation }) => gradation};
`

export const StyledCreatorInfo = styled.div`
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 0;
`

export const StyledCreatorInfoIconBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  padding: 0;
  margin: 0 auto;
  background: ${Colors.GRAY_BLUE};
`

export const StyledOfficialIcon = styled(Official)`
  margin-left: 4px;
  display: inline-block;
`

export const StyledScrollBarRange = styled.div`
  position: relative;
  width: 100%;
  height: 24px;
`

const scrollEffect = keyframes`
  60% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
`

export const StyledScrollBar = styled.div<{ barColor?: string; beforeBarColor?: string }>`
  border: 0;
  width: 1px;
  height: 24px;
  overflow: hidden;
  position: absolute;
  margin: 0 2px;
  left: calc((100% - 5px) / 2);
  right: calc((100% - 5px) / 2);
  background: ${({ barColor }) => barColor || Colors.LIGHT_GRAY};
  &:before {
    background: ${({ beforeBarColor }) => beforeBarColor || Colors.WHITE};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 24px;
    -webkit-animation: ${scrollEffect} 2.5s infinite;
    animation: ${scrollEffect} 2.5s infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
`

export const StyledScrollBarTransparent = styled.div`
  border: 0;
  width: 5px;
  height: 24px;
  overflow: hidden;
  position: absolute;
  margin: 0 auto;
  background: transparent;
  left: calc((100% - 5px) / 2);
  right: calc((100% - 5px) / 2);
  top: 0;
`

export const StyledScrollBarBall = styled.div<{ ballColor?: string }>`
  background: transparent;
  content: '';
  width: 5px;
  height: 24px;
  position: absolute;
  top: 24px;
  left: 0;
  -webkit-animation: ${scrollEffect} 2.5s infinite;
  animation: ${scrollEffect} 2.5s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  &:before {
    background: ${({ ballColor }) => ballColor || Colors.WHITE};
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const StyledViewMain = styled.div<{ hasHeaderImg?: boolean }>`
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  scroll-snap-align: start;
  background-color: ${({ hasHeaderImg }) => (hasHeaderImg ? 'transparent' : Colors.BK_GRAY)};
`
export const StyledViewMainContents = styled.div<{ bgColor?: string }>`
  min-height: 100vh;
  position: relative;
  top: -20px;
  margin-bottom: -20px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 44px 0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background: ${({ bgColor }) => bgColor || 'none'};
`

export const StyledDefaultIconWarapper = styled.div<{ bgColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  background: ${({ bgColor }) => (bgColor ? bgColor : Colors.VERY_LIGHT_GRAY)};
`

export const StyledLogoIconField = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledSnapParent = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
`

export const StyledDummyItem = styled.div`
  width: 44px;
  height: 40px;
  position: absolute;
`

export const StyledLoginBtnWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  margin: auto;
`

const moveUpDown = keyframes`
  0%, 100% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
`

export const StyledEventJoiningWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${moveUpDown} 1s linear infinite;
`

export const StyledGeneralEventJoiningWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
`

export const StyledGeneralEventIcon = styled.img`
  position: absolute;
  height: 76px;
  margin: -10px 0 0 15px;
  z-index: -1;
`

export const StyledGeneralSpeechBubbleWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  width: 125px;
  justify-content: center;
  align-items: center;
  animation: ${moveUpDown} 1s linear infinite;
`

export const StyledGeneralSpeechBubble = styled.img`
  position: absolute;
  height: 32px;
  margin: -15px 15px 0px 15px;
  z-index: -1;
`

export const StyledGeneralSpeechBubbleLabel = styled.div`
  position: absolute;
  bottom: 2px;
  margin: -11px 52px 0px 15px;
  z-index: 1;
  font-size: 12px;
  font-weight: bold;
  color: #ff0079;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white,
    2px 2px 0px #bfbfbf;
`
